import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../global/Layout'
import ContactWrapper from '../layouts/ContactWrapper'
import SimpleSection from '../layouts/SimpleSection'
import Footer from '../layouts/Footer'
import {
  ContentWrapperStyled,
  SimpleNumberedListStyled,
  SimpleDottedListStyled,
  SimpleListItemStyled,
  CustomLinkStyled,
} from '../common/common'
import {
  Heading1,
  Heading2,
  Typo4,
} from '../components/Typography'

const policyLinkItems = [
  {
    href: '#overview',
    to: 'overview',
    label: 'Overview',
  },
  {
    href: '#data-collect',
    to: 'data-collect',
    label: 'What personal data do we collect',
  },
  {
    href: '#legal-bases',
    to: 'legal-bases',
    label: 'Explaining the legal bases we rely on',
  },
  {
    href: '#consent',
    to: 'consent',
    label: 'Consent',
  },
  {
    href: '#obligations',
    to: 'obligations',
    label: 'Contractual obligations',
  },
  {
    href: '#compliance',
    to: 'compliance',
    label: 'Legal compliance',
  },
  {
    href: '#interest',
    to: 'interest',
    label: 'Legitimate interest',
  },
  {
    href: '#data-usage',
    to: 'data-usage',
    label: 'How we use your data',
  },
  {
    href: '#data-protection',
    to: 'data-protection',
    label: 'How we protect your data',
  },
  {
    href: '#data-retention',
    to: 'data-retention',
    label: 'How long will we keep your data',
  },
  {
    href: '#data-process',
    to: 'data-process',
    label: 'Where your personal data may be processed',
  },
  {
    href: '#your-rights',
    to: 'your-rights',
    label: 'What are your rights over your personal data',
  },
  {
    href: '#data-marketing',
    to: 'data-marketing',
    label: 'How can you stop the use of your personal data for marketing',
  },
  {
    href: '#regulator',
    to: 'regulator',
    label: 'Contacting the Regulator',
  },
]

const PrivacyPolicyPage = ({ data }) => (
  <Layout>
    <ContactWrapper
      car={data.carThree.childImageSharp.fluid}
    />
    <SimpleSection>
      <ContentWrapperStyled>
        <Heading1 as="h1">
          Privacy Policy
        </Heading1>
        <SimpleNumberedListStyled>
          {policyLinkItems.map(({ href, to, label }) => (
            <SimpleListItemStyled key={href}>
              <CustomLinkStyled
                href={href}
                to={to}
                spy
                smooth
                offset={-100}
                duration={500}
              >
                {label}
              </CustomLinkStyled>
            </SimpleListItemStyled>
          ))}
        </SimpleNumberedListStyled>
        <Heading2 as="h2" id="overview">
          Overview
        </Heading2>
        <Typo4>
          AutoLoad IT Ltd. is a company registered in England and Wales (which is referred to as ‘we’ or ‘us’ in this policy).
        </Typo4>
        <Typo4>
          We are committed to protecting and respecting any personal information you share with us.
        </Typo4>
        <Typo4>
          This statement describes what types of information we collect from you, how it is used by us, how we share it with others, how you can manage the information we hold and how you can contact us.
        </Typo4>
        <Typo4>
          We will always give you the option not to receive marketing communications from us. We will never send you unsolicited ‘junk’ email or communications or share your data with anyone else who might. We do not sell your information to third parties, but we do work closely with selected partners who help us to provide you with the information, products and services that you request from us. For example, vehicle portals who provide advertising to our customers.
        </Typo4>
        <Typo4>
          The contents of this statement may change from time to time so you may wish to check this page occasionally to ensure you are still happy to share your information with us. Where possible, we will also contact you directly to notify you of any substantial changes.
        </Typo4>
        <Typo4>
          This version of our Privacy Statement is live from 26th November 2019.
        </Typo4>
        <Heading2 as="h2" id="data-collect">
          What personal data do we collect
        </Heading2>
        <Typo4>
          We collect information about you and your company when you engage with our website, or speak to our communication centre. We only collect information which is necessary, relevant and adequate for the purpose you are providing it for.
        </Typo4>
        <Typo4>
          We may also obtain information about you or your company from some third parties, i.e. portals, or other software suppliers. Some of this information does not identify you personally, but provides us with information about how you use our services and engage with us (we use this information to improve our services and make them more useful to you). The information we collect includes some or all of the following:
        </Typo4>
        <SimpleNumberedListStyled>
          <SimpleListItemStyled>
            <Typo4>
              Name (including title);
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Company Name;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Address;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Phone number;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Email address;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              The date and time you used our services;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              The pages you visited on our website and how long you visited us for;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Your IP address;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Your GPS location (where you have permitted access to this);
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              The internet browser and devices you are using;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              <Link to="/cookie-policy">Cookie</Link>, Pixels or Beacon information (for more information please see our <Link to="/cookie-policy">Cookie Policy</Link>
);
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              The website address from which you accessed our website;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Details of any transactions between you and us or our businesses;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Where you engage with us in a business context, we may collect your job title, company contact details (including email addresses), fleet size and company details (some of which we may obtain from an online or public business directories);
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Voice recordings of calls you make to our communication centre;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              “Live chat” records;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Any information within correspondence you send to us and
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Where you act on behalf of a business, any information we collect as part of business telemarketing activity.
            </Typo4>
          </SimpleListItemStyled>
        </SimpleNumberedListStyled>
        <Heading2 as="h2" id="legal-bases">
          Explaining the legal bases we rely on
        </Heading2>
        <Typo4>
          The law on data protection sets out a number of different bases for which a company may collect and process your personal data, including:
        </Typo4>
        <Heading2 as="h2" id="consent">
          Consent
        </Heading2>
        <Typo4>
          In specific situations, we can collect and process your data with your consent. For example, when you tick a box to receive email newsletters.
        </Typo4>
        <Typo4>
          When collecting your personal data, we’ll always make clear to you which data is necessary for us to collect and process in connection with a particular service.
        </Typo4>
        <Heading2 as="h2" id="obligations">
          Contractual obligations
        </Heading2>
        <Typo4>
          In certain circumstances, we need your personal data to comply with our contractual obligations. For example, if you order a system from us.
        </Typo4>
        <Heading2 as="h2" id="compliance">
          Legal compliance
        </Heading2>
        <Typo4>
          If the law requires us to, we may need to collect and process your data. For example, we can pass on details of people involved in fraud or other criminal activity affecting us to law enforcement.
        </Typo4>
        <Heading2 as="h2" id="interest">
          Legitimate interest
        </Heading2>
        <Typo4>
          In specific situations, we collect and process your data to pursue our legitimate interests in a way which you might reasonably expect, as part of running our business, and which does not materially impact your rights, freedom or interests.
        </Typo4>
        <Typo4>
          For example we may use your purchase history with us to make you personalised offers.
        </Typo4>
        <Typo4>
          We may use your personal data to send you offers by post, email, or contact you by telephone to advise you of offers we think may be of interest to you.
        </Typo4>
        <Heading2 as="h2" id="data-usage">
          How we use your data
        </Heading2>
        <Typo4>
          AutoLoad IT Ltd. will only process information that is necessary for the purpose for which it has been collected. You will always have the option not to receive marketing communications from us (and you can withdraw your consent or object at any time).
        </Typo4>
        <Typo4>
          The reasons for processing your data are detailed below;
        </Typo4>
        <Typo4>
          Software and hardware sales: We collect data in the performance of a contract for the sale, servicing, maintenance or repair or to provide a service to ensure the contract is fulfilled.
        </Typo4>
        <Typo4>
          <strong>Financing Systems: </strong>
          We will process your data in order to obtain finance for you to purchase the system.
        </Typo4>
        <Typo4>
          <strong>Employee Information: </strong>
          We collect and store your personal data as part of our legal obligation linked to your contract of employment.
        </Typo4>
        <Typo4>
          <strong>Recruitment: </strong>
          We have a legal obligation to share your personal data with Experian who are a credit reference agency who provide us with financial background checks prior to you commencing employment with us.
        </Typo4>
        <Heading2 as="h2" id="data-protection">
          How we protect your data
        </Heading2>
        <Typo4>
          We take steps to ensure that any third-party partners who handle your information comply with data protection legislation and protect your information just as we do. We only disclose personal information that is necessary for them to provide the service that they are undertaking on our behalf. We will aim to anonymise your information or use aggregated non-specific data sets where ever possible. We do not sell your information to third parties, but we do work closely with our third party suppliers who fulfil business activities for us (like hardware suppliers, finance companies, marketing and market research agencies).
        </Typo4>
        <Typo4>
          Third party advertising partners may set cookies on your devices through our site to collect your information. We do not control their use of your data. Further information about our advertising partners and their use of data can be found via our privacy preference centre under the More Info tab in our cookie policy.
        </Typo4>
        <Heading2 as="h2" id="data-retention">
          How long will we keep your data
        </Heading2>
        <Typo4>
          We will not hold your personal information in an identifiable format for any longer than is necessary. If you are a customer or otherwise have a relationship with us we will hold personal information about you for a longer period than if we have obtained your details in connection with a prospective relationship.
        </Typo4>
        <Typo4>
          If we have a relationship with you (e.g. you are a customer or supplier), we hold your personal information for 6 years from the date our relationship ends. We hold your personal information for this period to establish, bring or defend legal claims where necessary. Our relationship may end for a number of reasons including where the software or maintenance license expires.
        </Typo4>
        <Typo4>
          Where we have obtained your personal information following a request for information, quotation or any other information on any of our products or services, we hold your personal information for 3 years from the date we collect that information, unless during that period we form a relationship with you e.g. you purchase or lease a system. We hold your personal information for this period to give us an opportunity to form a relationship with you.
        </Typo4>
        <Typo4>
          The only exceptions to the periods mentioned above are where:
        </Typo4>
        <SimpleDottedListStyled>
          <SimpleListItemStyled>
            <Typo4>
              The law requires us to hold your personal information for a longer period, or delete it sooner;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Where you have raised a legal claim, complaint or concern regarding a product or service offered by us, in which case we will retain your information for a period of 6 years following the date of that complaint or query;
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              You exercise your right to have the information erased (where it applies) and we do not need to hold it in connection with any of the reasons permitted or required under the law (see further How can I manage the information we hold about you).
            </Typo4>
          </SimpleListItemStyled>
        </SimpleDottedListStyled>
        <Typo4>
          <strong>Who do we share your data with?</strong>
        </Typo4>
        <Typo4>
          We do not sell your information to third parties, but we do work closely with our third party suppliers who fulfil business activities for us (like finance companies, marketing and market research etc.)
        </Typo4>
        <Typo4>
          However, we may from time-to-time disclose your information to the following categories of companies or organisations to which we pass the responsibility to handle services on our behalf:  customer contact centres, direct marketing communications agencies and consultants, market research and market analytics service providers, our legal and other professional advisors.
        </Typo4>
        <Typo4>
          There are circumstances where we share your information within our company in order to fulfil orders, transactions, handle complaints or provide you with a service or information that you have requested.
        </Typo4>
        <Heading2 as="h2" id="data-process">
          Where your personal data may be processed
        </Heading2>
        <Typo4>
          Due to the nature of our business, there may be some instances where your information is processed or stored outside of the EU. In those instances, we will ensure that appropriate safeguards are in place for that transfer and storage as required by applicable law.
        </Typo4>
        <Heading2 as="h2" id="your-rights">
          What are your rights over your personal data
        </Heading2>
        <Typo4>
          You have the right as an individual to access your personal information we hold about you and make corrections if necessary. You also have the right to withdraw any consent you have previously given us and ask us to erase information we hold about you. You can also object to us using your personal information (where we rely on our business interests to process and use your personal information).
        </Typo4>
        <Typo4>
          You have a number of rights in relation to your personal information under data protection law. In relation to most rights, we will ask you for information to confirm your identity and, where applicable, to help us search for your personal information. Except in rare cases, we will respond to you within 30 days after we have received any request (including any identification documents requested).
        </Typo4>
        <Typo4>
          You can exercise the above rights and/or manage your information by contacting us using the details below:
        </Typo4>
        <Typo4>
          Post: Data Protection Team, AutoLoad IT Ltd., Administration Centre, 664 Victoria Road, South Ruislip, Middlesex, United Kingdom, HA4 0LN
        </Typo4>
        <Typo4>
          Phone:

          <a href="tel:4402039654500">+44 (0)20 3965 4500</a>
        </Typo4>
        <Typo4>
          Email:

          <a href="mailto:info@autoloadit.com">info@autoloadit.com</a>
        </Typo4>
        <Heading2 as="h2" id="data-marketing">
          How can you stop the use of your personal data for marketing
        </Heading2>
        <Typo4>
          There are several ways you can stop direct marketing communications from us:
        </Typo4>
        <SimpleDottedListStyled>
          <SimpleListItemStyled>
            <Typo4>
              Click the ‘unsubscribe’ link in any email communication that we send you. We will then stop any further emails from that particular division.
            </Typo4>
          </SimpleListItemStyled>
          <SimpleListItemStyled>
            <Typo4>
              Write to Data Protection Team, AutoLoad IT Ltd., Administration Centre, 664 Victoria Road, South Ruislip, Middlesex, United Kingdom, HA4 0LN
            </Typo4>
          </SimpleListItemStyled>
        </SimpleDottedListStyled>
        <Typo4>
          Please note that you may continue to receive communications for a short period after changing your preferences while our systems are fully updated.
        </Typo4>
        <Heading2 as="h2" id="regulator">
          Contacting the regulator
        </Heading2>
        <Typo4>
          If you are unhappy, you have the right to lodge a complaint with a data protection regulator in the United Kingdom. The contact details for the Information Commissioner’s Office, the data protection regulator in the UK, are below:
        </Typo4>
        <Typo4>
          Post: AutoLoad IT Ltd., Administration Centre, 664 Victoria Road, South Ruislip, Middlesex, United Kingdom, HA4 0LN
        </Typo4>
        <Typo4>
          Call:

          <a href="tel:4402039654500">+44 (0)20 3965 4500</a>
        </Typo4>
        <Typo4>
          Email:

          <a href="mailto:info@autoloadit.com">info@autoloadit.com</a>
        </Typo4>
      </ContentWrapperStyled>
    </SimpleSection>
    <Footer />
  </Layout>
)

export default PrivacyPolicyPage

export const query = graphql`
  query {
    carThree: file(relativePath: { eq: "img-car-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
